import React from 'react'

const Redirect = ({ pageContext, location }) => {
  const isBrowser = () => typeof window !== "undefined";

  const redirect = () => {
    let redirectToUrl;
    if(location.hash) {
      console.log("entered if")
      redirectToUrl = pageContext.anchors?.find(anchor => anchor.from === location.hash)?.to || pageContext.toPath;
    }
    else {
      redirectToUrl = pageContext.toPath;
    }

    isBrowser() && window.location.replace(redirectToUrl);
  }

  return (
    <>
      {redirect()}
    </>
  )
}

export default Redirect